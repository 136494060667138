import React from 'react';

function HowItWorks() {
  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold text-gray-900 text-center mb-8">
          How CareCompare Works
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {/* Step 1 */}
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <i className="fas fa-question-circle text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Ask GenAI</h3>
            <p className="text-gray-600">
              Simply ask for healthcare services, and AI finds the best options for you.
            </p>
          </div>

          {/* Step 2 */}
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <i className="fas fa-sliders-h text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Compare Providers</h3>
            <p className="text-gray-600">
              See side-by-side comparisons of cost, quality, and location.
            </p>
          </div>

          {/* Step 3 */}
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <i className="fas fa-check-circle text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Book or Negotiate</h3>
            <p className="text-gray-600">
              Schedule services or let AI negotiate the best price for you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
