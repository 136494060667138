import React from 'react';

const Notifications = ({ notifications, markAsRead, markAllAsRead }) => {
  return (
    <div className="bg-white p-4 rounded shadow max-w-md border border-gray-200 mx-auto my-8">
      <h3 className="font-bold mb-4 text-lg flex justify-between items-center">
        Notifications
        {notifications.some((n) => !n.read) && (
          <button
            onClick={markAllAsRead}
            className="text-blue-500 text-sm hover:underline"
          >
            Mark All as Read
          </button>
        )}
      </h3>
      <ul>
        {notifications.map((notification) => (
          <li
            key={notification.id}
            className={`mb-2 p-2 rounded shadow-sm flex justify-between items-center ${
              notification.read ? 'bg-gray-200' : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            <div>
              <p className="text-gray-800">{notification.message}</p>
              <p className="text-xs text-gray-500">
                {new Date(notification.timestamp).toLocaleDateString()} at{' '}
                {new Date(notification.timestamp).toLocaleTimeString()}
              </p>
            </div>
            {!notification.read && (
              <button
                onClick={() => markAsRead(notification.id)}
                className="text-xs text-blue-500 hover:underline ml-4"
              >
                Mark as Read
              </button>
            )}
          </li>
        ))}
        {notifications.length === 0 && (
          <p className="text-center text-gray-500">No notifications available</p>
        )}
      </ul>
    </div>
  );
};

export default Notifications;
