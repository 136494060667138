import React, { useState } from "react";
import careScoreData from "../data/careScoreData";
import ProviderCard from "../components/ProviderCard";

const CareScore = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("careScore");

  // Filter and sort the data based on user input
  const filteredData = careScoreData
    .filter((provider) =>
      provider.providerName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "careScore") {
        if (b.careScore === a.careScore) return a.cost - b.cost; // Secondary sort by cost
        return b.careScore - a.careScore;
      }
      if (sortBy === "cost") {
        if (a.cost === b.cost) return b.careScore - a.careScore; // Secondary sort by CareScore
        return a.cost - b.cost;
      }
      return 0;
    });

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="p-6 bg-white shadow rounded-lg text-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">
          Find the Best Providers
        </h1>
        <p className="text-gray-600 mt-2">
          Discover healthcare providers ranked by cost, quality, and compliance.
        </p>
      </div>

      {/* Search and Sort Section */}
      <div className="mb-6 flex flex-col md:flex-row items-center justify-center md:space-x-4 space-y-4 md:space-y-0">
        <input
          type="text"
          placeholder="Search providers..."
          className="p-3 border border-gray-300 rounded-lg shadow-sm w-full md:w-1/3 focus:outline-none focus:ring-2 focus:ring-blue-400"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="p-3 border border-gray-300 rounded-lg shadow-sm w-full md:w-auto focus:outline-none focus:ring-2 focus:ring-blue-400"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
        >
          <option value="careScore">Sort by CareScore</option>
          <option value="cost">Sort by Cost</option>
        </select>
      </div>

      {/* Providers Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredData.length > 0 ? (
          filteredData.map((provider) => (
            <ProviderCard key={provider.providerId} provider={provider} />
          ))
        ) : (
          <p className="col-span-full text-center text-gray-600">
            No providers match your search criteria.
          </p>
        )}
      </div>
    </div>
  );
};

export default CareScore;
