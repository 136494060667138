import React from 'react';

const About = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="hero-section bg-gray-100 py-20 text-center">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">About CareCompare</h1>
        <p className="text-lg text-gray-600 mb-8">
          Empowering Your Healthcare Choices with Transparency and Simplicity.
        </p>
        <a href="#team" className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 transition duration-200">
          Meet Our Team
        </a>
      </div>

      {/* Mission, Vision, and Values Section */}
      <section className="py-16 bg-white">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">Our Mission, Vision & Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <ValuesCard icon="🌟" title="Our Mission" description="To make healthcare choices transparent and accessible for everyone." />
            <ValuesCard icon="🔍" title="Our Vision" description="A future where healthcare is clear, accessible, and comparable." />
            <ValuesCard icon="🤝" title="Our Values" description="Transparency, Accessibility, and Trust." />
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">Why Choose CareCompare?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureCard title="Transparency" description="Clear, accessible pricing data to help you make informed healthcare choices." />
            <FeatureCard title="User-Friendly Design" description="Our intuitive interface makes comparing healthcare services simple and stress-free." />
            <FeatureCard title="Advanced Tools" description="Powerful filters and comparison features to find the right services for your needs." />
            <FeatureCard title="Compliance" description="Fully aligned with CMS Hospital Pricing Transparency rules, ensuring trust and accountability." />
          </div>
        </div>
      </section>

      {/* Meet the Team Section */}
      <section className="py-16 bg-white" id="team">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">Meet the Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            <TeamMember image="/images/robot-assistant.png" name="Ram Papineni" title="CEO" bio="Ram brings over 20 years of expertise in AI and data-driven insights, championing transparency and innovation in healthcare." />
            <TeamMember image="/images/robot-assistant.png" name="Agent" title="CTO" bio="Agent leads our tech team with expertise in data security and platform design." />
            <TeamMember image="/images/robot-assistant.png" name="Agent" title="CFO" bio="Agent ensures our financial health while supporting our mission of transparency." />
            <TeamMember image="/images/robot-assistant.png" name="Agent" title="COO" bio="Agent focuses on operational excellence and customer satisfaction." />
          </div>
        </div>
      </section>

      {/* Compliance & Transparency Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">Compliance & Transparency</h2>
          <p className="text-lg text-gray-600">
            CareCompare is fully compliant with CMS Hospital Pricing Transparency requirements. We are committed to accuracy and transparency in healthcare pricing, building trust with every user.
          </p>
        </div>
      </section>
    </div>
  );
};

// Component for Mission, Vision, Values Cards
const ValuesCard = ({ icon, title, description }) => (
  <div className="p-4 text-center bg-white rounded-lg shadow-md">
    <div className="text-5xl mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

// Component for Why Choose Us Features
const FeatureCard = ({ title, description }) => (
  <div className="p-6 bg-white rounded-lg shadow-md">
    <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

// Team Member Component for Meet the Team Section
const TeamMember = ({ image, name, title, bio }) => (
  <div className="bg-white p-8 rounded-lg shadow-md text-center transition-transform transform hover:scale-105">
    <img src={image} alt={name} className="mx-auto rounded-full h-32 w-32 object-cover mb-4" />
    <h3 className="text-xl font-bold text-gray-800">{name}</h3>
    <p className="text-gray-500">{title}</p>
    <p className="text-gray-600 mt-2">{bio}</p>
  </div>
);

export default About;
