import React from 'react';

function CallToAction() {
  return (
    <div className="bg-gradient-to-r from-indigo-600 to-purple-500 text-white py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold mb-4">Join the Revolution in Healthcare</h2>
        <p className="text-lg mb-6">
          Experience a smarter, more transparent way to manage your healthcare needs.
        </p>
        <button className="bg-white text-indigo-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-all duration-300">
          Get Started
        </button>
      </div>
    </div>
  );
}

export default CallToAction;
