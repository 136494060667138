import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import SearchCompare from './pages/SearchCompare';
import Dashboard from './pages/Dashboard';
import HelpSupport from './pages/HelpSupport';
import Notifications from './pages/Notifications';
import ServiceDetails from './pages/ServiceDetails';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import CareScore from './pages/CareScore';
import { mockServices, mockNotifications } from './data/mockData';

function App() {
  const [services, setServices] = useState([]);
  const [notifications, setNotifications] = useState([]);

  // Calculate unread notifications dynamically
  const unreadCount = notifications.filter((notification) => !notification.read).length;

  // Load mock data on mount
  useEffect(() => {
    setServices(mockServices);
    setNotifications(
      mockNotifications.map((notification) => ({
        ...notification,
        read: false, // Mark all notifications as unread initially
      }))
    );
  }, []);

  // Mark a single notification as read
  const markAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  };

  // Mark all notifications as read
  const markAllAsRead = () => {
    setNotifications((prev) => prev.map((notification) => ({ ...notification, read: true })));
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-50">
        <Navbar unreadCount={unreadCount} />
        <main className="flex-grow pb-20 overflow-hidden">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/search"
              element={<SearchCompare services={services} loading={false} />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/help" element={<HelpSupport />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/carescore" element={<CareScore />} />
            <Route
              path="/notifications"
              element={
                <Notifications
                  notifications={notifications}
                  markAsRead={markAsRead}
                  markAllAsRead={markAllAsRead}
                />
              }
            />
            <Route path="/service/:id" element={<ServiceDetails />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
