const careScoreData = [
  {
    providerId: 1,
    providerName: "Downtown Medical Center",
    location: "New York, NY",
    location_coordinates: { lat: 40.7128, lng: -74.006 },
    careScore: 92,
    cost: 500,
    quality: 4.8,
    compliance: 4.9,
    description: "A highly rated medical center offering various services.",
    billing_code: "12345",
    negotiated_rate: 450,
    expiration_date: "2024-12-31",
    contact_info: "info@downtownmedical.com",
    prices: {
      gross: "550",
      discountedCash: "600",
      negotiatedRates: "450",
      deIdentifiedMinMax: "400-600",
    },
    reviews: [
      { user: "Alice", rating: 5, comment: "Excellent service and staff!" },
      { user: "Bob", rating: 4, comment: "Clean and professional." },
    ],
  },
  {
    providerId: 2,
    providerName: "MetroCare Specialists",
    location: "Dallas, TX",
    location_coordinates: { lat: 32.7767, lng: -96.797 },
    careScore: 90,
    cost: 520,
    quality: 4.7,
    compliance: 4.9,
    description: "Specialized care with a focus on patient satisfaction.",
    billing_code: "23456",
    negotiated_rate: 500,
    expiration_date: "2025-01-15",
    contact_info: "contact@metrocare.com",
    prices: {
      gross: "600",
      discountedCash: "520",
      negotiatedRates: "500",
      deIdentifiedMinMax: "$450-$650",
    },
    reviews: [
      { user: "Charlie", rating: 4, comment: "Great service but a bit pricey." },
      { user: "Dana", rating: 5, comment: "Exceptional care!" },
    ],
  },
  {
    providerId: 3,
    providerName: "Austin Family Clinic",
    location: "Austin, TX",
    location_coordinates: { lat: 30.2672, lng: -97.7431 },
    careScore: 87,
    cost: 450,
    quality: 4.6,
    compliance: 4.8,
    description: "Family-oriented clinic providing top-notch healthcare services.",
    billing_code: "34567",
    negotiated_rate: 420,
    expiration_date: "2024-11-30",
    contact_info: "hello@austinfamilyclinic.com",
    prices: {
      gross: "$480",
      discountedCash: "450",
      negotiatedRates: "420",
      deIdentifiedMinMax: "$400-$500",
    },
    reviews: [
      { user: "Eve", rating: 4, comment: "Friendly and helpful staff." },
      { user: "Frank", rating: 5, comment: "Perfect for family needs!" },
    ],
  },
];

export default careScoreData;
