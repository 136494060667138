import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import careScoreData from "../data/careScoreData"; // Assuming mock data

const ServiceDetails = () => {
  const { id } = useParams();
  const [service, setService] = useState(null);

  useEffect(() => {
    const foundService = careScoreData.find((s) => s.providerId === parseInt(id, 10));
    setService(foundService);
  }, [id]);

  if (!service) {
    return (
      <div className="p-8 text-center">
        <Helmet>
          <title>Service Not Found - CareCompare</title>
        </Helmet>
        <h2 className="text-2xl font-bold text-gray-800">Service Not Found</h2>
        <p className="text-gray-600">
          The service you are looking for is unavailable.
        </p>
      </div>
    );
  }

  // Default location for the map
  const defaultLocation = { lat: 37.7749, lng: -122.4194 };
  const mapCenter = service.location_coordinates || defaultLocation;

  // Helper function for formatting price
  const formatPrice = (price) => (price || price === 0 ? `$${price}` : "N/A");

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Dynamic Helmet for Titles */}
      <Helmet>
        <title>{service.providerName} - CareCompare</title>
        <meta
          name="description"
          content={`Find details for ${service.providerName}, including pricing, location, and reviews.`}
        />
      </Helmet>

      {/* Header Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mb-6 text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-2">
          {service.providerName}
        </h1>
        <p className="text-gray-600">
          {service.description || "No description available."}
        </p>
      </div>

      {/* Main Content Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Provider Details */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
            Provider Details
          </h2>
          <ul className="grid grid-cols-2 gap-x-4 gap-y-2 text-gray-700">
            <li>
              <strong>Location:</strong> {service.location || "N/A"}
            </li>
            <li>
              <strong>CareScore:</strong> {service.careScore || "N/A"}
            </li>
            <li>
              <strong>Cost:</strong> {formatPrice(service.cost)}
            </li>
            <li>
              <strong>Quality:</strong> {service.quality || "N/A"}
            </li>
            <li>
              <strong>Compliance:</strong> {service.compliance || "N/A"}
            </li>
            <li>
              <strong>Contact Info:</strong> {service.contact_info || "N/A"}
            </li>
          </ul>
        </div>

        {/* Pricing Information */}
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
            Pricing Information
          </h2>
          <ul className="grid grid-cols-2 gap-x-4 gap-y-2 text-gray-700">
            <li>
              <strong>Gross Charges:</strong> {formatPrice(service.prices?.gross)}
            </li>
            <li>
              <strong>Discounted Cash Price:</strong>{" "}
              {formatPrice(service.prices?.discountedCash)}
            </li>
            <li>
              <strong>Payer-Specific Rates:</strong>{" "}
              {formatPrice(service.prices?.negotiatedRates)}
            </li>
            <li>
              <strong>De-identified Min/Max:</strong>{" "}
              {service.prices?.deIdentifiedMinMax || "N/A"}
            </li>
          </ul>
        </div>
      </div>

      {/* Location Map Section */}
      <div className="bg-white shadow rounded-lg p-6 mt-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Location Map</h2>
        <MapContainer
          center={[mapCenter.lat, mapCenter.lng]}
          zoom={13}
          style={{ height: "300px", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[mapCenter.lat, mapCenter.lng]}>
            <Popup>{service.providerName || "Service Location"}</Popup>
          </Marker>
        </MapContainer>
      </div>

      {/* Reviews Section */}
      {service.reviews && service.reviews.length > 0 && (
        <div className="bg-white shadow rounded-lg p-6 mt-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">User Reviews</h2>
          <ul className="space-y-4">
            {service.reviews.map((review, index) => (
              <li
                key={index}
                className="p-4 border rounded-lg shadow-sm bg-gray-50"
              >
                <p className="font-semibold text-gray-800">{review.user}</p>
                <p className="text-yellow-500">
                  {"★".repeat(review.rating)}
                  {"☆".repeat(5 - review.rating)}
                </p>
                <p className="text-gray-600">{review.comment}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ServiceDetails;
