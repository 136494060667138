import React from 'react';

export const getBadges = ({ careScore, price, cost, compliance, ratings }) => {
  let badges = [];

  // Use price if available, otherwise use cost for "Best Value" check
  const effectivePrice = price || cost;

  if (careScore && careScore >= 90) {
    badges.push({ text: "Top Rated", cssClass: "badge badge-top-rated" });
  }
  if (effectivePrice && effectivePrice <= 450) {
    badges.push({ text: "Best Value", cssClass: "badge badge-best-value" });
  }
  if (compliance && compliance >= 4.8) {
    badges.push({ text: "High Compliance", cssClass: "badge badge-high-compliance" });
  }
  if (ratings && ratings >= 4) {
    badges.push({ text: "Highly Rated", cssClass: "badge badge-highly-rated" });
  }

  return badges;
};

export const renderRatings = (ratings) => {
  if (ratings === null || ratings === undefined) {
    return <span className="text-gray-400 italic">Not Rated</span>;
  }
  return (
    <span className="text-yellow-500">
      {'★'.repeat(ratings || 0)}
      {'☆'.repeat(5 - (ratings || 0))}
    </span>
  );
};

/**
 * Normalizes the service data to unify the attribute names for both services and providers.
 *
 * @param {Object} data - The service or provider data.
 * @param {string} source - The source of the data (e.g., "carescore", "searchcompare").
 * @returns {Object} A normalized service data object.
 */
export const normalizeServiceData = (data, source) => {
  const normalizedData = {
    id: data.id || data.serviceId || data.providerId || `default-${source}-${Date.now()}`,
    service_name: data.service_name || data.providerName || 'N/A',
    provider_name: data.provider_name || data.providerName || data.provider || 'N/A',
    location: data.location || 'N/A',
    price: data.price || data.cost || 'N/A',
    ratings: data.ratings || data.quality || null,
    compliance: data.compliance || null,
    source,
  };

  if (source === 'carescore') {
    normalizedData.careScore = data.careScore || null;
  }

  return normalizedData;
};
