import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBadges, normalizeServiceData } from '../utils/Utils';
import ServiceDetailModal from './ServiceDetailModal';
import '../styles/badge.css';

const ProviderCard = ({ provider }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  if (!provider) {
    console.error('Provider data is missing!');
    return null;
  }

  const normalizedProvider = normalizeServiceData(provider, 'carescore');
  const badges = getBadges(normalizedProvider);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleViewDetailsPage = () => {
    if (!normalizedProvider.id) {
      console.error('Provider ID is missing!', normalizedProvider);
      alert('Service ID is unavailable. Please contact support.');
      return;
    }
    navigate(`/service/${normalizedProvider.id}`);
  };

  return (
    <div className="relative bg-white p-6 pt-12 shadow-md rounded-lg transform transition-transform duration-300 hover:scale-105">
      {/* Badge Rendering (Multiple Badges) */}
      <div className="absolute top-2 left-2 flex flex-wrap space-x-2 z-10">
        {badges.map((badge, index) => (
          <div key={index} className={badge.cssClass}>
            {badge.text}
          </div>
        ))}
      </div>

      {/* Provider Name - Centered */}
      <h2 className="text-lg font-bold text-gray-800 text-center mb-3">
        {normalizedProvider.provider_name || 'Unknown Provider'}
      </h2>

      {/* Provider Details - 2 Column Inline Layout */}
      <div className="grid grid-cols-2 gap-x-4 text-sm text-gray-600 mb-3">
        <p>
          <strong>CareScore:</strong>{' '}
          <span className="text-green-600 font-semibold">{normalizedProvider.careScore || 'N/A'}</span>
        </p>
        <p>
          <strong>Cost:</strong>{' '}
          <span className="font-semibold">${normalizedProvider.price || 'N/A'}</span>
        </p>
        <p>
          <strong>Quality:</strong>{' '}
          <span className="font-semibold">{normalizedProvider.ratings ? `${normalizedProvider.ratings}/5` : 'N/A'}</span>
        </p>
        <p>
          <strong>Compliance:</strong>{' '}
          <span className="font-semibold">{normalizedProvider.compliance ? `${normalizedProvider.compliance}/5` : 'N/A'}</span>
        </p>
      </div>

      {/* Action Buttons - Centered and Aligned */}
      <div className="flex justify-center items-center space-x-4 mt-4">
        <button
          onClick={openModal}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1.5 rounded-md text-sm shadow-sm transition ease-in-out duration-200"
          aria-label={`Quick view details for ${normalizedProvider.provider_name}`}
        >
          View
        </button>
        <button
          onClick={handleViewDetailsPage}
          className="border border-blue-500 text-blue-500 hover:bg-blue-100 px-4 py-1.5 rounded-md text-sm shadow-sm transition ease-in-out duration-200"
          aria-label={`View more details for ${normalizedProvider.provider_name}`}
        >
          Details
        </button>
      </div>

      {/* Service Detail Modal */}
      {isModalOpen && (
        <ServiceDetailModal
          service={normalizedProvider}
          isOpen={isModalOpen}
          onClose={closeModal}
          source="carescore"
        />
      )}
    </div>
  );
};

export default ProviderCard;
