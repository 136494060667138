import React from 'react';
import { getBadges, renderRatings, normalizeServiceData } from '../utils/Utils';
import '../styles/badge.css';

const ServiceCard = ({ service, onClick, onCompareChange, compareList = [], showCareFields = true }) => {
  const normalizedService = normalizeServiceData(service, "searchcompare");
  const badges = getBadges(normalizedService) || []; // Ensure badges is always an array
  const isChecked = compareList?.some((item) => item.id === service.id);

  return (
    <div
      className="relative p-4 pt-8 border rounded-lg shadow cursor-pointer hover:shadow-2xl transition-shadow duration-300"
      onClick={onClick}
    >
      {/* Badge Rendering (Multiple Badges) */}
      {badges.length > 0 && (
        <div className="absolute top-2 left-2 flex flex-wrap space-x-2 z-10">
          {badges.map((badge, index) => (
            badge?.cssClass && badge?.text ? (
              <div key={index} className={badge.cssClass}>
                {badge.text}
              </div>
            ) : null
          ))}
        </div>
      )}

      {/* Service Details */}
      <p className="font-semibold text-gray-800">
        <strong>Service Name:</strong> {normalizedService.service_name}
      </p>
      <p className="text-gray-600">
        <strong>Location:</strong> {normalizedService.location}
      </p>
      <p className="text-gray-600">
        <strong>Provider:</strong> {normalizedService.provider_name}
      </p>
      <p className="text-gray-600">
        <strong>Price:</strong> ${normalizedService.price}
      </p>
      <p className="text-gray-600">
        <strong>Ratings:</strong> {renderRatings(normalizedService.ratings)}
      </p>

      {/* Conditionally Render Care Fields */}
      {showCareFields && (
        <>
          <p className="text-gray-600">
            <strong>CareScore:</strong> {normalizedService.careScore || 'N/A'}
          </p>
          <p className="text-gray-600">
            <strong>Quality:</strong> {normalizedService.quality ? `${normalizedService.quality}/5` : 'N/A'}
          </p>
          <p className="text-gray-600">
            <strong>Compliance:</strong> {normalizedService.compliance ? `${normalizedService.compliance}/5` : 'N/A'}
          </p>
        </>
      )}

      {/* Action Buttons */}
      <div className="flex justify-between items-center mt-4">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={isChecked}
            aria-label={`Compare service: ${normalizedService.service_name}`}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => onCompareChange(e, service)}
            className="mr-2 cursor-pointer"
          />
          <span className="cursor-pointer hover:underline">Compare</span>
        </label>
        <button
          onClick={onClick}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1.5 rounded-md text-sm shadow-sm transition ease-in-out duration-200"
          aria-label={`View service details for ${normalizedService.service_name}`}
        >
          View
        </button>
      </div>
    </div>
  );
};

export default ServiceCard;
