import React from 'react';

function KeyFeatures() {
  return (
    <div className="py-12 bg-white">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">
          Why Choose CareCompare?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature 1 */}
          <div className="bg-gray-50 p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <i className="fas fa-brain text-blue-500 text-4xl mb-4"></i>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              GenAI Personalization
            </h3>
            <p className="text-gray-600">
              AI learns your preferences and delivers personalized healthcare recommendations.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="bg-gray-50 p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <i className="fas fa-chart-line text-blue-500 text-4xl mb-4"></i>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              Transparent Pricing
            </h3>
            <p className="text-gray-600">
              Predict costs and identify hidden expenses for smarter healthcare decisions.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="bg-gray-50 p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <i className="fas fa-star text-blue-500 text-4xl mb-4"></i>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              CareScore Index
            </h3>
            <p className="text-gray-600">
              Find top-rated providers with AI-driven CareScore ratings for cost, quality, and value.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeyFeatures;
