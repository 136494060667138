import { BookOpenIcon, QuestionMarkCircleIcon, ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect, useCallback } from 'react';
import fuzzysort from 'fuzzysort';
import { mockFaqs, mockDefinitions } from '../data/mockData'; // Import mock data

const HelpSupport = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [faqs, setFaqs] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch mock data on mount
  useEffect(() => {
    setLoading(true);
    try {
      setFaqs(mockFaqs);
      setGlossary(mockDefinitions);
    } catch (error) {
      console.error('Error fetching mock data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle search query changes
  const handleSearchQueryChange = useCallback(
    (e) => {
      const query = e.target.value;
      setSearchQuery(query);

      // Generate dynamic search suggestions
      if (query) {
        const suggestions = fuzzysort
          .go(query, [...faqs.map((faq) => faq.question), ...glossary.map((term) => term.term)])
          .map((result) => result.target);
        setSearchSuggestions(suggestions);
      } else {
        setSearchSuggestions([]);
      }
    },
    [faqs, glossary]
  );

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setSearchSuggestions([]); // Close suggestions dropdown
  };

  // Highlight matched search terms in results
  const highlightMatch = (text, query) => {
    const match = fuzzysort.single(query, text);
    if (!match) return text;

    return (
      <>
        {match.target.split('').map((char, i) =>
          match.indexes.includes(i) ? (
            <span key={i} className="bg-yellow-200 font-bold">
              {char}
            </span>
          ) : (
            char
          )
        )}
      </>
    );
  };

  // Filter FAQs and Glossary dynamically
  const filteredFaqs = searchQuery
    ? fuzzysort.go(searchQuery, faqs, { key: 'question' }).map((result) => result.obj)
    : [];

  const filteredGlossary = searchQuery
    ? fuzzysort.go(searchQuery, glossary, { key: 'term' }).map((result) => result.obj)
    : [];

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-center mb-6">Help & Support</h2>

      {/* Search Bar */}
      <div className="relative text-center mb-8">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Search glossary or FAQ..."
          aria-label="Search glossary or FAQ"
          className="w-full max-w-md p-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
        />
        {/* Dynamic Suggestions */}
        {searchSuggestions.length > 0 && (
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-full max-w-md bg-white border rounded-lg shadow-md z-10 max-h-40 overflow-y-auto">
            {searchSuggestions.map((suggestion, index) => (
              <div
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer text-sm"
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Show loading or results */}
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        <>
          {/* Glossary Results Section */}
          {searchQuery && (
            <div className="mb-8 p-6 bg-white shadow-md rounded-lg">
              <div className="flex items-center mb-4">
                <BookOpenIcon className="w-6 h-6 text-blue-500 mr-2" />
                <h3 className="text-xl font-semibold">Glossary Results</h3>
              </div>
              {filteredGlossary.length > 0 ? (
                filteredGlossary.map((term, index) => (
                  <div key={index} className="flex items-start mb-2">
                    <span className="font-semibold text-gray-800">{highlightMatch(term.term, searchQuery)}:</span>
                    <span className="ml-2 text-gray-600">{term.description}</span>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No glossary results found.</p>
              )}
            </div>
          )}

          {/* FAQ Results Section */}
          {searchQuery && (
            <div className="mb-8 p-6 bg-white shadow-md rounded-lg">
              <div className="flex items-center mb-4">
                <QuestionMarkCircleIcon className="w-6 h-6 text-blue-500 mr-2" />
                <h3 className="text-xl font-semibold">Frequently Asked Questions (FAQ)</h3>
              </div>
              {filteredFaqs.length > 0 ? (
                filteredFaqs.map((faq, index) => (
                  <div key={index} className="mb-4">
                    <p className="font-semibold text-gray-800">{highlightMatch(faq.question, searchQuery)}</p>
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No FAQs match your search.</p>
              )}
            </div>
          )}
        </>
      )}

      {/* Support Ticket Form */}
      <div className="p-6 bg-white shadow-md rounded-lg">
        <div className="flex items-center mb-4">
          <ChatBubbleLeftIcon className="w-6 h-6 text-blue-500 mr-2" />
          <h3 className="text-xl font-semibold">Submit a Support Ticket</h3>
        </div>
        <form>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-semibold mb-1">Name</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-semibold mb-1">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              className="w-full p-3 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-semibold mb-1">Description</label>
            <textarea
              id="description"
              placeholder="Describe your issue"
              className="w-full p-3 border border-gray-300 rounded-lg"
              rows="4"
            ></textarea>
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Submit Ticket
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HelpSupport;
