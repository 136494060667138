import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Chatbot from './Chatbot'; // Import Chatbot component

function Navbar({ unreadCount }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [chatbotOpen, setChatbotOpen] = useState(false); // State to manage Chatbot visibility

  // Function to toggle dropdown
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.dropdown')) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [dropdownOpen]);

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center py-4">
        {/* Brand Logo */}
        <div className="flex items-center space-x-4">
          <Link to="/" className="flex items-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/711/711769.png"
              alt="CareCompare Logo"
              className="h-10"
            />
            <h1 className="text-gray-800 text-2xl font-bold ml-2">CareCompare</h1>
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="hidden md:flex space-x-8">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500 font-semibold'
                : 'text-gray-600 hover:text-blue-500'
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/search"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500 font-semibold'
                : 'text-gray-600 hover:text-blue-500'
            }
          >
            Search & Compare
          </NavLink>
          <NavLink
            to="/carescore"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500 font-semibold'
                : 'text-gray-600 hover:text-blue-500'
            }
          >
            CareScore
          </NavLink>
          <NavLink
            to="/marketplace"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500 font-semibold'
                : 'text-gray-600 hover:text-blue-500'
            }
          >
            Marketplace
          </NavLink>
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500 font-semibold'
                : 'text-gray-600 hover:text-blue-500'
            }
          >
            My Health Dashboard
          </NavLink>

          {/* Dropdown for Secondary Links */}
          <div className="relative dropdown">
            <button
              onClick={toggleDropdown}
              className="text-gray-600 hover:text-blue-500 font-medium focus:outline-none flex items-center"
            >
              More
              <i className="fas fa-chevron-down ml-2 text-sm"></i>
            </button>
            {dropdownOpen && (
              <div className="absolute bg-white shadow-md rounded-lg py-2 mt-2 w-40 z-10">
                <NavLink
                  to="/insights"
                  className={({ isActive }) =>
                    isActive
                      ? 'block px-4 py-2 bg-blue-500 text-white rounded'
                      : 'block px-4 py-2 text-gray-600 hover:bg-blue-500 hover:text-white rounded'
                  }
                  onClick={() => setDropdownOpen(false)} // Close dropdown on click
                >
                  Insights & Trends
                </NavLink>
                <NavLink
                  to="/help"
                  className={({ isActive }) =>
                    isActive
                      ? 'block px-4 py-2 bg-blue-500 text-white rounded'
                      : 'block px-4 py-2 text-gray-600 hover:bg-blue-500 hover:text-white rounded'
                  }
                  onClick={() => setDropdownOpen(false)} // Close dropdown on click
                >
                  Help & Support
                </NavLink>
              </div>
            )}
          </div>
        </div>

        {/* Right-Side Section */}
        <div className="flex items-center space-x-4">
          {/* Notifications */}
          <div className="relative">
            <NavLink
              to="/notifications"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-600 hover:text-blue-500'
              }
            >
              <img
                src="https://cdn-icons-png.flaticon.com/512/1827/1827392.png"
                alt="Notifications"
                className={`h-6 w-6 ${
                  unreadCount > 0 ? 'text-red-500' : 'text-gray-600'
                }`}
              />
              {unreadCount > 0 && (
                <span className="absolute -top-2 -right-3 bg-red-500 text-xs rounded-full w-5 h-5 flex items-center justify-center text-white shadow-sm">
                  {unreadCount}
                </span>
              )}
            </NavLink>
          </div>

          {/* Virtual Assistant Button */}
          <button
            onClick={() => setChatbotOpen(true)} // Show Chatbot
            className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 flex items-center justify-center"
            aria-label="Open Virtual Assistant"
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/4712/4712027.png"
              alt="Virtual Assistant"
              className="h-6 w-6"
            />
          </button>

          {/* User Avatar */}
          <img
            src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
            alt="User Avatar"
            className="h-10 w-10 rounded-full border border-gray-300"
          />
        </div>
      </div>

      {/* Chatbot */}
      {chatbotOpen && (
        <Chatbot onClose={() => setChatbotOpen(false)} /> // Pass onClose to handle closing
      )}
    </nav>
  );
}

export default Navbar;
