import React from 'react';

function Contact() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Centered Heading */}
      <h1 className="text-3xl font-bold mb-6 text-center">Contact Us</h1>

      {/* Intro Paragraph (Left-Aligned for Readability) */}
      <p className="text-lg leading-relaxed">
        We’re here to help! Reach out to us with questions, feedback, or support requests.
      </p>

      {/* Support Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4">Support</h2>
      <ul className="list-none space-y-2">
        <li>
          <span className="font-semibold">Email: </span>
          <a
            href="mailto:support@carecompare.com"
            className="text-blue-500 hover:underline hover:text-blue-600 transition-colors"
          >
            support@carecompare.com
          </a>
        </li>
        <li>
          <span className="font-semibold">Phone: </span>
          <a
            href="tel:1800CARECOMP"
            className="text-blue-500 hover:underline hover:text-blue-600 transition-colors"
          >
            1-800-CARE-COMP
          </a>
        </li>
        <li>
          <span className="font-semibold">Address: </span>
          CareCompare HQ, 123 HealthTech Ave, San Francisco, CA 94105
        </li>
      </ul>

      {/* Support Hours (Centered for Emphasis) */}
      <h2 className="text-xl font-semibold mt-8 mb-4">Support Hours</h2>
      <p className="text-lg leading-relaxed text-left">
        <span className="font-semibold">Monday - Friday:</span> 9 AM - 5 PM (PST)
      </p>

      {/* Feedback Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4">Feedback</h2>
      <p className="text-lg leading-relaxed">
        We value your input to improve CareCompare. Share your thoughts at{' '}
        <a
          href="mailto:feedback@carecompare.com"
          className="text-blue-500 hover:underline hover:text-blue-600 transition-colors"
        >
          feedback@carecompare.com
        </a>.
      </p>
    </div>
  );
}

export default Contact;
