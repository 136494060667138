import React from 'react';

function Dashboard() {
  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="container mx-auto">
        {/* Page Title */}
        <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">
          My Health Dashboard
        </h1>

        {/* Placeholder Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Booked Services */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">
              Booked Services
            </h2>
            <p className="text-gray-600">
              View all the services you've booked through CareCompare.
            </p>
          </div>

          {/* Recommendations */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">
              Recommendations
            </h2>
            <p className="text-gray-600">
              Get personalized suggestions for follow-ups and cost-saving opportunities.
            </p>
          </div>

          {/* Cost Insights */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">
              Cost Insights
            </h2>
            <p className="text-gray-600">
              Track your healthcare spending and discover cost trends.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
