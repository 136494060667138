export const mockServices = Array.from({ length: 200 }, (_, i) => ({
  id: i + 1,
  service_name: [
    "MRI Scan",
    "X-ray",
    "Blood Test",
    "CT Scan",
    "Ultrasound",
    "COVID-19 Testing",
    "Physical Therapy",
    "Flu Shot",
    "Cholesterol Screening",
  ][Math.floor(Math.random() * 9)],
  location: [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Phoenix",
    "San Diego",
    "San Francisco",
    "Austin",
    "Miami",
  ][Math.floor(Math.random() * 9)],
  provider_name: [
    "Aetna",
    "Cigna",
    "Blue Cross",
    "United Healthcare",
    "Humana",
    "Kaiser Permanente",
    "Molina Healthcare",
    "Anthem",
  ][Math.floor(Math.random() * 8)],
  price: Math.floor(Math.random() * 950) + 50, // Random price between 50 and 1000
  ratings: Math.floor(Math.random() * 5) + 1, // Ratings between 1 and 5
  distance: Math.floor(Math.random() * 50) + 1, // Distance between 1 and 50 miles
  billing_code: `BC${Math.floor(Math.random() * 10000)}`,
  negotiated_rate: Math.floor(Math.random() * 900) + 50, // Negotiated rate between 50 and 950
  expiration_date: `202${Math.floor(Math.random() * 5) + 5}-12-31`, // Random expiration date
}));

export const mockPlans = Array.from({ length: 50 }, (_, i) => ({
  plan_name: `Health Plan ${i + 1}`,
  plan_id: `HP${i + 100}`,
  plan_market_type: ["individual", "group"][Math.floor(Math.random() * 2)],
  providers: Array.from({ length: 5 }, () => ({
    npi: `${Math.floor(1000000000 + Math.random() * 9000000000)}`,
    tin: {
      type: ["npi", "ein"][Math.floor(Math.random() * 2)],
      value: `${Math.floor(1000000000 + Math.random() * 9000000000)}`,
    },
    services_offered: Array.from({ length: 4 }, () => ({
      service_code: `SVC-${Math.floor(Math.random() * 1000)}`,
      description: [
        "MRI Scan",
        "X-ray",
        "Blood Test",
        "CT Scan",
        "Ultrasound",
        "Physical Therapy",
        "Flu Shot",
      ][Math.floor(Math.random() * 7)],
      billing_code: `BC${Math.floor(Math.random() * 10000)}`,
    })),
    negotiated_rates: Array.from({ length: 4 }, () => ({
      negotiated_rate: Math.floor(Math.random() * 900) + 50,
      billing_class: ["professional", "institutional"][Math.floor(Math.random() * 2)],
      negotiated_type: ["negotiated", "fee schedule", "percentage"][Math.floor(Math.random() * 3)],
      expiration_date: `202${Math.floor(Math.random() * 5) + 5}-12-31`,
      service_code: `SVC-${Math.floor(Math.random() * 1000)}`,
    })),
  })),
}));

export const mockProviders = Array.from({ length: 100 }, (_, i) => ({
  npi: `${Math.floor(1000000000 + Math.random() * 9000000000)}`,
  tin: {
    type: ["npi", "ein"][Math.floor(Math.random() * 2)],
    value: `${Math.floor(1000000000 + Math.random() * 9000000000)}`,
  },
  services_offered: Array.from({ length: 5 }, () => ({
    service_code: `SVC-${Math.floor(Math.random() * 1000)}`,
    description: [
      "MRI Scan",
      "X-ray",
      "Blood Test",
      "CT Scan",
      "Ultrasound",
      "Physical Therapy",
    ][Math.floor(Math.random() * 6)],
    billing_code: `BC${Math.floor(Math.random() * 10000)}`,
  })),
  negotiated_rates: Array.from({ length: 5 }, () => ({
    negotiated_rate: Math.floor(Math.random() * 900) + 50,
    billing_class: ["professional", "institutional"][Math.floor(Math.random() * 2)],
    negotiated_type: ["negotiated", "fee schedule", "percentage"][Math.floor(Math.random() * 3)],
    expiration_date: `202${Math.floor(Math.random() * 5) + 5}-12-31`,
    service_code: `SVC-${Math.floor(Math.random() * 1000)}`,
  })),
}));

export const mockFaqs = [
  {
    question: 'What is a negotiated rate?',
    answer: 'The negotiated rate is the agreed-upon price between a healthcare provider and an insurance company.',
  },
  {
    question: 'How can I find affordable MRI scans?',
    answer: 'Use the search and compare tool to find providers offering the best prices for MRI scans in your area.',
  },
  {
    question: 'What does gross charges mean?',
    answer: 'Gross charges are the total price for a service before any discounts or insurance adjustments.',
  },
  {
    question: 'How can I determine my deductible?',
    answer: 'Your deductible amount is specified in your insurance plan details. Check your plan documents or consult your provider.',
  },
];

export const mockDefinitions = [
  {
    term: 'Gross Charges',
    description: 'The total price charged by a provider before any discounts.',
  },
  {
    term: 'Negotiated Rate',
    description: 'The price agreed upon between a provider and an insurer for a specific service.',
  },
  {
    term: 'Co-Pay',
    description: 'A fixed amount paid by the patient at the time of service.',
  },
  {
    term: 'Deductible',
    description: 'The amount a patient must pay out-of-pocket before insurance covers services.',
  },
];

export const mockNotifications = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
  message: `Notification: Price for ${
    ['MRI Scan', 'Flu Shot'][Math.floor(Math.random() * 2)]
  } has been updated!`,
  timestamp: new Date(2024, Math.floor(Math.random() * 12), Math.floor(Math.random() * 28) + 1).toISOString(),
}));

export const mockCategories = [
  {
    id: 1,
    name: 'Pricing Transparency',
    faqs: [
      {
        question: 'What is a negotiated rate?',
        answer: 'The price agreed upon between provider and insurer.',
      },
      {
        question: 'How do I find cash prices?',
        answer: 'Look for discounted cash prices in the pricing transparency section.',
      },
    ],
  },
  {
    id: 2,
    name: 'Insurance Terms',
    faqs: [
      {
        question: 'What is a deductible?',
        answer: 'The amount you pay before insurance covers expenses.',
      },
      {
        question: 'What is co-insurance?',
        answer: 'A percentage of costs you share with insurance after meeting your deductible.',
      },
    ],
  },
];
