import React from 'react';
import BackToTop from '../components/BackToTop';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-6">
      <div className="container mx-auto text-center">
        <p className="text-xs">
          © 2024 CareCompare. All rights reserved. |
          <a href="/about" className="text-blue-400 ml-2 hover:underline" aria-label="About Us">About</a> |
          <a href="/privacy" className="text-blue-400 ml-2 hover:underline" aria-label="Privacy Policy">Privacy</a> |
          <a href="/terms" className="text-blue-400 ml-2 hover:underline" aria-label="Terms and Conditions">Terms</a> |
          <a href="/contact" className="text-blue-400 ml-2 hover:underline" aria-label="Contact Us">Contact</a>
        </p>
        <div className="flex justify-center mt-4 space-x-6">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
            className="hover:scale-110 transition-transform"
          >
            <i className="fab fa-facebook-f text-blue-400 hover:text-blue-500"></i>
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
            className="hover:scale-110 transition-transform"
          >
            <i className="fab fa-twitter text-blue-400 hover:text-blue-500"></i>
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
            className="hover:scale-110 transition-transform"
          >
            <i className="fab fa-linkedin-in text-blue-400 hover:text-blue-500"></i>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
