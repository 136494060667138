import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const ServiceDetailModal = ({ service, isOpen, onClose, source }) => {
  if (!isOpen || !service) return null; // Ensure modal is only rendered when open

  // Default location
  const defaultLocation = { lat: 37.7749, lng: -122.4194 };
  const mapCenter = service.location_coordinates || defaultLocation;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      role="dialog"
      aria-labelledby="service-detail-title"
      aria-modal="true"
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[80vh] overflow-auto relative">
        {/* Close Button */}
        <div className="absolute top-2 right-2 z-50">
          <button
            onClick={onClose}
            className="bg-red-500 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-red-600 transition duration-200"
            aria-label="Close"
          >
            ✕
          </button>
        </div>

        {/* Header Section */}
        <div className="bg-blue-500 text-white p-6 sticky top-0 z-10">
          <h2 id="service-detail-title" className="text-xl font-bold text-center">
            {source === 'carescore' ? service.providerName || "Provider Details" : service.service_name || "Service Details"}
          </h2>
        </div>

        {/* Scrollable Content */}
        <div className="p-6 space-y-6">
          {/* Provider Details */}
          <div className="space-y-2">
            <h3 className="text-lg font-bold text-center text-gray-800">Provider Details</h3>
            <ul className="grid grid-cols-2 gap-4 text-gray-700">
              <li>
                <strong>Location:</strong> {service.location || "N/A"}
              </li>
              <li>
                <strong>Provider:</strong> {service.provider_name || service.providerName || "N/A"}
              </li>
              <li>
                <strong>Billing Code:</strong> {service.billing_code || "N/A"}
              </li>
              <li>
                <strong>Negotiated Rate:</strong> ${service.negotiated_rate || "N/A"}
              </li>
              <li>
                <strong>Expiration Date:</strong> {service.expiration_date || "N/A"}
              </li>
              {source === 'carescore' && (
                <>
                  <li>
                    <strong>CareScore:</strong> {service.careScore || "N/A"}
                  </li>
                  <li>
                    <strong>Quality:</strong> {service.quality ? `${service.quality}/5` : "N/A"}
                  </li>
                  <li>
                    <strong>Compliance:</strong> {service.compliance ? `${service.compliance}/5` : "N/A"}
                  </li>
                </>
              )}
            </ul>
          </div>

          {/* Description */}
          {service.description && (
            <div>
              <h3 className="text-lg font-bold text-gray-800">Description</h3>
              <p className="text-gray-700">{service.description}</p>
            </div>
          )}

          {/* Contact Info */}
          {service.contact_info && (
            <div>
              <h3 className="text-lg font-bold text-gray-800">Contact Info</h3>
              <p className="text-gray-700">{service.contact_info}</p>
            </div>
          )}

          {/* Ratings */}
          {service.ratings && (
            <div>
              <h3 className="text-lg font-bold text-gray-800">Ratings</h3>
              <p className="text-yellow-500 text-lg">
                {'★'.repeat(service.ratings)}
                {'☆'.repeat(5 - service.ratings)}
              </p>
            </div>
          )}

          {/* Location Map */}
          <div>
            <h3 className="text-lg font-bold text-gray-800 mb-4">Location Map</h3>
            <MapContainer
              center={[mapCenter.lat, mapCenter.lng]}
              zoom={13}
              style={{ height: "300px", width: "100%" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[mapCenter.lat, mapCenter.lng]}>
                <Popup>{service.provider_name || service.providerName || "Service Location"}</Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetailModal;
