import React from 'react';

function Terms() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Centered Heading */}
      <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions</h1>

      {/* Intro Paragraph (Left-Aligned for Readability) */}
      <p className="text-lg leading-relaxed">
        Welcome to CareCompare! By accessing or using our platform, you agree to comply with these Terms and Conditions.
      </p>

      {/* Use of Platform Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4">Use of Platform</h2>
      <ul className="list-disc pl-5 space-y-2">
        <li>The platform is intended for personal, non-commercial use only.</li>
        <li>Users must not engage in unauthorized data scraping or misuse of the service.</li>
      </ul>

      {/* Content Accuracy Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4 text-left">Content Accuracy</h2>
      <p className="text-lg leading-relaxed">
        CareCompare strives to provide accurate information but does not guarantee the completeness
        or reliability of healthcare provider data or CareScore metrics.
      </p>

      {/* Liability Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4 text-left">Liability</h2>
      <p className="text-lg leading-relaxed">
        CareCompare is not responsible for decisions made based on platform insights, disputes
        with healthcare providers listed on our platform, or any indirect damages arising from the use of our services.
      </p>
    </div>
  );
}

export default Terms;
