import React from 'react';
import BackToTop from '../components/BackToTop';

function Privacy() {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Centered Heading */}
      <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy</h1>

      {/* Intro Paragraph (Left-Aligned for Readability) */}
      <p className="text-lg leading-relaxed">
        CareCompare values your privacy and is committed to protecting your personal information.
        This Privacy Policy outlines how we collect, use, and safeguard your data when you use our platform.
      </p>

      {/* Information We Collect Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4 text-left">Information We Collect</h2>
      <ul className="list-disc pl-5 space-y-2">
        <li>
          <span className="font-semibold">Personal Information:</span> Name, email address, phone number, and payment details (if applicable).
        </li>
        <li>
          <span className="font-semibold">Usage Data:</span> Device type, browser type, IP address, and pages visited on our site.
        </li>
        <li>
          <span className="font-semibold">Health Preferences:</span> Data you choose to provide when using CareScore or comparing providers.
        </li>
      </ul>

      {/* How We Use Your Information Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4 text-left">How We Use Your Information</h2>
      <ul className="list-disc pl-5 space-y-2">
        <li>To personalize your healthcare recommendations.</li>
        <li>To improve platform features based on user behavior.</li>
        <li>To provide customer support and troubleshoot issues.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      {/* Your Rights Section */}
      <h2 className="text-xl font-semibold mt-8 mb-4 text-left">Your Rights</h2>
      <p className="text-lg leading-relaxed">
        You have the right to access, modify, or delete your data. You can also opt out of marketing communications at any time.
      </p>
    </div>
  );
}

export default Privacy;
