import React from 'react';

function Testimonials() {
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-8">What Our Users Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Testimonial 1 */}
          <div className="bg-gray-50 p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <p className="italic text-gray-600 mb-4">
              "CareCompare helped me find affordable healthcare options in minutes!"
            </p>
            <h4 className="text-gray-900 font-semibold">- John Doe</h4>
          </div>

          {/* Testimonial 2 */}
          <div className="bg-gray-50 p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <p className="italic text-gray-600 mb-4">
              "The AI-driven insights made choosing the right provider so easy!"
            </p>
            <h4 className="text-gray-900 font-semibold">- Jane Smith</h4>
          </div>

          {/* Testimonial 3 */}
          <div className="bg-gray-50 p-6 rounded-lg shadow hover:shadow-lg transform transition-transform duration-300 hover:scale-105">
            <p className="italic text-gray-600 mb-4">
              "Negotiating costs with the AI saved me $300 on my MRI!"
            </p>
            <h4 className="text-gray-900 font-semibold">- Mark Lee</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
