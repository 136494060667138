import React, { useState, useEffect } from 'react';
import ServiceCard from '../components/ServiceCard';
import ServiceDetailModal from '../components/ServiceDetailModal';
import { mockServices } from '../data/mockData'; // Import mock data
import { debounce } from 'lodash';
import Fuse from 'fuse.js';

const SearchCompare = () => {
  const [filters, setFilters] = useState({
    location: '',
    searchQuery: '',
    minPrice: '',
    maxPrice: '',
  });
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOption, setSortOption] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [compareList, setCompareList] = useState([]); // Track services selected for comparison
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Pagination: current page
  const [compareError, setCompareError] = useState(''); // For handling compare error message
  const itemsPerPage = 6; // Define how many items to display per page

  const allSearchSuggestions = ['MRI', 'X-ray', 'Blood Test', 'CT Scan', 'Ultrasound', 'Aetna', 'Cigna'];
  const allLocationSuggestions = ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix', 'San Diego'];

  const fuseOptions = {
    keys: ['service_name', 'provider_name'],
    threshold: 0.3,  // Controls fuzziness
  };

  useEffect(() => {
    try {
      const enrichedData = mockServices.map((service) => ({
        ...service,
        distance: Math.floor(Math.random() * 20 + 1),
        ratings: service.ratings || 0, // Default to 0 if ratings are missing
      }));
      setServices(enrichedData);
      setLoading(false);
    } catch (error) {
      console.error('Error loading mock data:', error);
      setError('Failed to load services. Please try again later.');
      setLoading(false);
    }
  }, []);

  const handleCompareChange = (e, service) => {
    if (e.target.checked) {
      if (compareList.length < 3) {
        setCompareList((prev) => [...prev, service]); // Add service to compare list
        setCompareError(''); // Clear error message when within limit
      } else {
        setCompareError('You can only compare up to 3 services at a time.');
        e.target.checked = false; // Uncheck the box if the limit is reached
      }
    } else {
      setCompareList((prev) => prev.filter((item) => item.id !== service.id)); // Remove service
      setCompareError(''); // Clear error message when services are removed
    }
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  const highlightMatch = (text, query) => {
    if (typeof text !== 'string') return text; // Ensure text is a string before using replace
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<mark>$1</mark>');
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilters({ ...filters, searchQuery: query });

    const fuse = new Fuse(services, fuseOptions);
    const results = fuse.search(query);
    const uniqueSuggestions = Array.from(new Set(results.map(result => result.item.service_name || result.item.provider_name)));
    setSearchSuggestions(uniqueSuggestions);
  };

  const handleLocationInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setFilters({ ...filters, location: query });

    const fuse = new Fuse(allLocationSuggestions, { threshold: 0.3 });
    const results = fuse.search(query);
    const uniqueSuggestions = Array.from(new Set(results.map(result => result.item)));
    setLocationSuggestions(uniqueSuggestions.map(item => ({ value: item, highlightedValue: highlightMatch(item, query) })));
  };

  const handleSearchSuggestionClick = (suggestion) => {
    setFilters({ ...filters, searchQuery: suggestion });
    setSearchSuggestions([]);
  };

  const handleLocationSuggestionClick = (suggestion) => {
    setFilters({ ...filters, location: suggestion });
    setLocationSuggestions([]);
  };

  const clearCompareList = () => {
    setCompareList([]);
    setCompareError(''); // Clear error when compare list is cleared
  };

  const renderRatings = (ratings) => {
    const fullStars = Math.floor(ratings); // Full stars
    const emptyStars = 5 - fullStars; // Empty stars

    return (
      <span className="text-yellow-500">
        {'★'.repeat(fullStars)} {/* Full yellow stars */}
        {'☆'.repeat(emptyStars)} {/* Empty stars */}
      </span>
    );
  };

  const filteredServices = services
    .filter((service) => {
      const matchesLocation =
        filters.location === '' ||
        (service.location && service.location.toLowerCase().includes(filters.location.toLowerCase()));

      const matchesSearchQuery =
        filters.searchQuery === '' ||
        (service.provider_name && service.provider_name.toLowerCase().includes(filters.searchQuery.toLowerCase())) ||
        (service.service_name && service.service_name.toLowerCase().includes(filters.searchQuery.toLowerCase()));

      const matchesMinPrice =
        filters.minPrice === '' || (service.price && service.price >= parseFloat(filters.minPrice));

      const matchesMaxPrice =
        filters.maxPrice === '' || (service.price && service.price <= parseFloat(filters.maxPrice));

      return matchesLocation && matchesSearchQuery && matchesMinPrice && matchesMaxPrice;
    })
    .sort((a, b) => {
      switch (sortOption) {
        case 'priceLowToHigh':
          return a.price - b.price;
        case 'priceHighToLow':
          return b.price - a.price;
        case 'ratingsHighToLow':
          return b.ratings - a.ratings;
        case 'distanceClosestFirst':
          return a.distance - b.distance;
        default:
          return 0;
      }
    });

  const loadMoreServices = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const currentServices = filteredServices.slice(0, currentPage * itemsPerPage);

  const bestValueService = filteredServices.reduce(
    (prev, curr) => (prev && prev.price < curr.price ? prev : curr),
    null
  );

  const topRatedService = filteredServices.reduce(
    (prev, curr) => (prev && prev.ratings > curr.ratings ? prev : curr),
    null
  );

  if (loading) {
    return (
      <div className="text-center py-10">
        Loading services...
      </div>
    );
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen relative pb-40">
      <div className="sticky top-0 z-50 bg-white shadow rounded-lg p-6 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
          <div className="relative">
            <label htmlFor="searchQuery" className="block text-sm font-bold uppercase mb-2">
              Search
            </label>
            <input
              id="searchQuery"
              type="text"
              placeholder="Search by provider or service"
              value={filters.searchQuery}
              onChange={handleSearchInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              aria-label="Search services or providers"
            />
            {searchSuggestions.length > 0 && (
              <div className="absolute z-10 bg-white border rounded-lg shadow-md max-h-40 overflow-y-auto">
                {searchSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    onClick={() => handleSearchSuggestionClick(suggestion)}
                    className="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                    dangerouslySetInnerHTML={{
                      __html: highlightMatch(suggestion, filters.searchQuery)
                    }}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="relative">
            <label htmlFor="location" className="block text-sm font-bold uppercase mb-2">
              Location
            </label>
            <input
              id="location"
              type="text"
              placeholder="Location (City or ZIP)"
              value={filters.location}
              onChange={handleLocationInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              aria-label="Search for a location"
            />
            {locationSuggestions.length > 0 && (
              <div className="absolute z-10 bg-white border rounded-lg shadow-md max-h-40 overflow-y-auto">
                {locationSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    onClick={() => handleLocationSuggestionClick(suggestion.value)}
                    className="px-4 py-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                    dangerouslySetInnerHTML={{ __html: suggestion.highlightedValue }}
                  />
                ))}
              </div>
            )}
          </div>

          <div>
            <label htmlFor="sortOption" className="block text-sm font-bold uppercase mb-2">
              Sort By
            </label>
            <select
              id="sortOption"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select</option>
              <option value="priceLowToHigh" className={sortOption === 'priceLowToHigh' ? 'bg-blue-500 text-white' : ''}>
                Price: Low to High
              </option>
              <option value="priceHighToLow" className={sortOption === 'priceHighToLow' ? 'bg-blue-500 text-white' : ''}>
                Price: High to Low
              </option>
              <option value="ratingsHighToLow" className={sortOption === 'ratingsHighToLow' ? 'bg-blue-500 text-white' : ''}>
                Ratings: High to Low
              </option>
              <option value="distanceClosestFirst" className={sortOption === 'distanceClosestFirst' ? 'bg-blue-500 text-white' : ''}>
                Distance: Closest First
              </option>
            </select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {currentServices.map((service) => (
          <ServiceCard
            key={service.id}
            service={service}
            onClick={() => handleServiceClick(service)}
            onCompareChange={handleCompareChange}
            isBestValue={service.id === bestValueService?.id}
            isTopRated={service.id === topRatedService?.id}
            compareList={compareList}
            showCareFields={false} // Ensure CareScore fields are hidden
          />
        ))}
      </div>

      {currentServices.length < filteredServices.length && (
        <div className="flex justify-center mt-6">
          <button
            onClick={loadMoreServices}
            className="px-6 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            Load More
          </button>
        </div>
      )}

      {/* Compare Services Panel */}
      {compareList.length > 0 && (
        <div className="fixed bottom-0 left-0 w-full bg-white shadow-lg p-4 border-t border-gray-300 z-50">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold text-blue-600">Compare Services</h2>
            <button
              onClick={clearCompareList}
              className="text-sm text-red-600 underline"
            >
              Clear All
            </button>
          </div>
          {compareError && (
            <div className="text-red-600 text-sm mb-2">{compareError}</div>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {compareList.map((service) => (
              <div key={service.id} className="p-4 border rounded-lg shadow bg-gray-50">
                <p><strong>Service:</strong> {service.service_name}</p>
                <p><strong>Provider:</strong> {service.provider_name}</p>
                <p><strong>Price:</strong> ${service.price}</p>
                <p><strong>Ratings:</strong> {renderRatings(service.ratings)}</p>
                <button
                  onClick={() =>
                    setCompareList((prev) =>
                      prev.filter((item) => item.id !== service.id)
                    )
                  }
                  className="mt-2 text-sm text-red-600 underline"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {isModalOpen && selectedService && (
        <ServiceDetailModal
          service={selectedService}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default SearchCompare;
