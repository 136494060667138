import React from 'react';

function HeroSection() {
  return (
    <div className="bg-gradient-to-br from-blue-500 to-indigo-600 text-white py-16">
      <div className="container mx-auto text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-6">
          Revolutionizing Healthcare Decisions with GenAI
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Discover smarter, faster, and more transparent ways to find the right healthcare for you.
        </p>
        <div className="flex justify-center">
          <input
            type="text"
            placeholder="Search for healthcare services (e.g., MRI, dentist)"
            className="w-3/4 md:w-1/2 px-4 py-3 rounded-l-lg border border-gray-300 focus:ring-2 focus:ring-blue-300"
          />
          <button className="bg-white text-blue-500 px-6 py-3 rounded-r-lg font-semibold hover:bg-gray-200">
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
