import React, { useState, useRef, useEffect } from 'react';
import Fuse from 'fuse.js';
import { mockFaqs, mockDefinitions } from '../data/mockData';

const Chatbot = ({ onClose }) => {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I assist you today?' },
  ]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const chatContainerRef = useRef(null);

  const normalizeText = (text) =>
    text.toLowerCase().replace(/[^\w\s]/gi, '').trim();

  const handleSend = () => {
    if (!input.trim()) return;

    const userMessage = input.trim();
    setMessages([...messages, { sender: 'user', text: userMessage }]);
    setInput('');

    setIsTyping(true);

    // Normalize user input
    const normalizedUserMessage = normalizeText(userMessage);

    // Fuse.js configuration
    const faqFuse = new Fuse(mockFaqs, {
      keys: ['question'],
      threshold: 0.4, // Adjust for sensitivity
    });

    const definitionFuse = new Fuse(mockDefinitions, {
      keys: ['term'],
      threshold: 0.4,
    });

    // Perform search
    const faqResult = faqFuse.search(userMessage);

    const definitionResult = definitionFuse.search(userMessage);

    // Determine response
    const botResponse =
      faqResult.length > 0
        ? faqResult[0].item.answer
        : definitionResult.length > 0
        ? definitionResult[0].item.description
        : "Sorry, I couldn't retrieve an answer at the moment.";

    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: botResponse },
      ]);
      setIsTyping(false);

      // Scroll to bottom after adding bot response
      handleScrollToBottom();
    }, 500);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleScrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    handleScrollToBottom();
  }, [messages]);

  if (minimized) {
    return (
      <div
        className="fixed bottom-5 right-5 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg cursor-pointer"
        onClick={() => setMinimized(false)}
      >
        Virtual Assistant
      </div>
    );
  }

  return (
    <div className="fixed bottom-5 right-5 bg-white border rounded-lg shadow-lg w-96 h-[32rem] flex flex-col">
      <div className="flex justify-between items-center p-4 border-b">
        <div className="text-lg font-bold">Virtual Assistant</div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setMinimized(true)}
            className="text-gray-500 hover:text-gray-700 font-bold text-lg focus:outline-none"
            aria-label="Minimize Chatbot"
          >
            _
          </button>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 font-bold text-xl focus:outline-none"
            aria-label="Close Chatbot"
          >
            ✕
          </button>
        </div>
      </div>

      <div
        ref={chatContainerRef}
        className="flex-grow p-4 overflow-y-auto flex flex-col space-y-2"
      >
        {messages.map((msg, index) => (
          <div
            key={index}
            className={msg.sender === 'bot' ? 'text-left' : 'text-right'}
          >
            <span
              className={`inline-block p-2 rounded-lg ${
                msg.sender === 'bot'
                  ? 'bg-gray-200 text-gray-800'
                  : 'bg-blue-500 text-white'
              }`}
            >
              {msg.text}
            </span>
          </div>
        ))}
        {isTyping && (
          <div className="text-left">
            <span className="inline-block p-2 rounded-lg bg-gray-200 italic text-sm animate-pulse">
              Typing...
            </span>
          </div>
        )}
      </div>

      <div className="p-4 border-t flex items-center">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type your message..."
          className="flex-grow border rounded p-2 mr-2 focus:ring-blue-500"
        />
        <button
          onClick={handleSend}
          className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
